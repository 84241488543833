import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Template from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import { SocialProof } from '@beelineloans/cx-library/src/components/layout/panels';
import CONST from '@beelineloans/cx-library/src/utils/constants';
// import { formatDate } from '@beelineloans/cx-library/src/utils/formatters';
// import { Tooltip, Span } from '@beelineloans/cx-library/src/components/typography';
import LandingPageContent from '../LandingPageContent';
import { ButtonText } from '../../../shared/GatsbyButton';

const RatesLink = styled(ButtonText)`
  text-decoration: underline;
  &:hover {
    color: ${(props) => props.theme.colours.background.creamFade};
  }
`;

// const TooltipStyled = styled(Tooltip)`
//   span {
//     display: none;
//   }
// `;

// const HeaderRate = styled(Span)`
//   display: none;
// `;

const LandingPage = ({ pageType }) => {
  const ratesRef = useRef();
  const isPurchase = pageType === 'purchase' || pageType === 'fha';

  const onSearch = () => {
    ratesRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  let subheadlineBlock = (
    <>
      Skip the BS application.
      <br />
      Get a{' '}
      <RatesLink onClick={onSearch} selected>
        low rate
      </RatesLink>{' '}
      you can rely on in 15 mins.
      <br />
      It&apos;s a no brainer.
    </>
  );

  let mainHeadLine = (
    <>
      Refi from your sofa.
      <br />
      It’s surprisingly simple.
    </>
  );

  if (pageType === 'purchase') {
    subheadlineBlock = (
      <>
        Apply in 10 effortless minutes.
        <br />
        Get a pre-approval you can rely on.
        <br />
        Choose from more loan options so you’re more
        <br />
        likely to qualify — and less likely to waste time.
      </>
    );

    mainHeadLine = (
      <>
        Get a home loan from
        <br />
        your sofa.
      </>
    );
  } else if (pageType === 'fha') {
    subheadlineBlock = (
      <>
        With down payments as low as 3.5%.
        <br />
        And lower rates than conventional loans.
        <br />
        It’s time to plan a mega housewarming.
      </>
    );

    mainHeadLine = <>Hooray for FHA!</>;
  }

  return (
    <Template
      headline={
        <>
          {mainHeadLine}
          {/* <HeaderRate id="headline-rate">{process.env.GATSBY_RATES_TEXAS}</HeaderRate>
          <TooltipStyled light alt={`You may call ${CONST.LINKS.EXTERNAL.PHONE.DASHED} to obtain current information.`}>
            Interest rate is based on a $300,000 loan with a 15 year fixed rate and a 1.98% APR and $1,517 monthly payment at 80% loan-to-value (LTV) and 1.666 discount points.
            Rate is accurate as of {formatDate(Date())}. You may call {CONST.LINKS.EXTERNAL.PHONE.DASHED} to obtain current information.
          </TooltipStyled>
          */}
        </>
      }
      subHeadline={subheadlineBlock}
      headerProps={{
        options: {
          videoType: isPurchase ? 'purchase' : 'refi',
          headerRatings: true,
          voomly: true
        }
      }}
      showSocialProof={isPurchase}
      antiFlicker
    >
      <SEO
        isRefi={!isPurchase}
        path={isPurchase ? CONST.LINKS.MAIN.LANDING.PURCHASE : CONST.LINKS.MAIN.LANDING.REFINANCE}
        title={isPurchase ? 'Get a home loan from your sofa in 15 mins' : 'Refi from your sofa'}
        description={
          isPurchase
            ? 'Skip the grueling application. Make offers like a boss with a Purchase-Ready Approval you can rely on.'
            : "In 15 minutes, know how much better off you'll be."
        }
      />
      <SocialProof dark={isPurchase} />
      <LandingPageContent pageType={pageType} ratesRef={ratesRef} />
    </Template>
  );
};

LandingPage.propTypes = {
  pageType: PropTypes.string
};

LandingPage.defaultProps = {
  pageType: 'purchase'
};

export default LandingPage;
