import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '@beelineloans/cx-library/src/components/layout/Container';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { applyEndpoint } from '@beelineloans/cx-library/src/utils/helpers';
import {
  NumberedListWithImage,
  TextAndImageV2,
  TextWithChecklistImg,
  PlantAndCoffee,
  Celebrate,
  FishAndText,
  LoanGuideConvo,
  VideoWithHeading,
  ReviewsWithSocial
} from '@beelineloans/cx-library/src/components/layout/panels';
import { Button } from '@beelineloans/cx-library/src/components/buttons';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import { H2, Paragraph } from '@beelineloans/cx-library/src/components/typography';
import SiteCopy from '@beelineloans/cx-library/src/utils/site-copy';
import Welcome from '@beelineloans/cx-library/src/images/welcome.png';
import Rates from '@beelineloans/cx-library/src/components/forms/calculator/Rates';

const NumberedListWithImageStyled = styled(NumberedListWithImage)`
  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    img {
      margin-top: 0;
    }
  }
`;

const ContainerYellow = styled(Container)`
  .container-inner {
    padding-top: 100px;
    padding-bottom: 40px;

    @media only screen and (${(props) => props.theme.mediaBreakpoints.desktop}) {
      padding-top: 153px;
      padding-bottom: 69px;
    }
  }
`;

const LandingPageContent = React.forwardRef(({ pageType, isDynamicLP, onSearchComplete, showDetails }, ratesRef) => {
  const isPurchase = pageType === 'purchase';
  const isFHA = pageType === 'fha';

  const numberedList = [
    {
      number: 1,
      noBorder: false,
      ...(isPurchase ? SiteCopy.numberedList.ApplyingIsEffortless : isFHA ? '' : SiteCopy.numberedList.LowRatesRefi)
    },
    {
      number: 2,
      noBorder: false,
      ...(isPurchase ? SiteCopy.numberedList.MoreOptionsOnePlace : isFHA ? '' : SiteCopy.numberedList.Radical)
    },
    {
      number: 3,
      noBorder: false,
      ...(isPurchase ? SiteCopy.numberedList.HelloCashOffer : isFHA ? '' : SiteCopy.numberedList.WhereYouStand)
    },
    {
      number: 4,
      noBorder: true,
      ...(isPurchase ? SiteCopy.numberedList.VeryOwnLoanGuide : isFHA ? '' : SiteCopy.numberedList.LoanGuide)
    }
  ];

  let mainHeading = null;
  let sideHeading = null;
  if (isDynamicLP) {
    if (!isPurchase) mainHeading = 'Skip the BS application. In just 15 minutes, you’ll know exactly how much better off you’ll be — it’s a no brainer.';
    if (isPurchase)
      sideHeading = (
        <>
          Home loans like
          <br />
          never before.
        </>
      );
  }

  return (
    <>
      <NumberedListWithImageStyled items={numberedList} cash={!isPurchase} mainHeading={mainHeading} sideHeading={sideHeading} />
      <ReviewsWithSocial googleRating={process.env.GATSBY_GOOGLE_REVIEW_NUM} bbbRating={process.env.GATSBY_BBB_REVIEW_NUM} />
      {isDynamicLP && (
        <VideoWithHeading
          backgroundColor={Theme.colours.background.navy}
          headingColour={Theme.colours.text.light}
          heading="Watch the trailer and see it in action."
          videoSrc={isPurchase ? CONST.VIDEOS.VOOMLY.EXPLAINER_PURCHASE : CONST.VIDEOS.VOOMLY.EXPLAINER_REFI}
          dangerouslyInsert={isDynamicLP}
          voomly
        />
      )}
      {!isPurchase && (
        <>
          <ContainerYellow backgroundColor={Theme.colours.background.yellow}>
            <H2>Our low rates are sweet. Check them out...</H2>
          </ContainerYellow>
          <div ref={ratesRef}>
            <Rates
              yellow
              showDivider
              isPurchase={isPurchase}
              onSearchComplete={onSearchComplete}
              showDetails={showDetails}
              source={isDynamicLP ? 'Personalised' : 'Landing Page'}
            />
          </div>
        </>
      )}
      {isPurchase && (
        <TextAndImageV2
          backgroundColor={Theme.colours.background.yellow}
          left
          h2={
            <>
              FHA has come a long way — and
              <br />
              mortgage insurance is cheaper.
            </>
          }
          image={{
            src: Welcome,
            mobileWidth: '315px',
            desktopWidth: '445px'
          }}
        >
          <Paragraph>So FHA might be the best option for you right now. </Paragraph>
          <Paragraph>Firstly rates are usually lower, so that helps.</Paragraph>
          <Paragraph>Then in 2023 mortgage insurance rates were reduced — so FHA may work out to be more affordable than a conventional loan.</Paragraph>
          <Paragraph>Down payment can be as low as 3.5%. There’s more flexibility with lower credit scores too.</Paragraph>
          <Paragraph>
            And how about this nice streamline feature — if you’ve been in the loan more than 210 days since your first payment and rates drop, you can refi without any income
            check or appraisal.
          </Paragraph>
          <Paragraph>The upsides of ownership and other big pluses make FHA a contender.</Paragraph>
          <Button to={applyEndpoint}>Apply Now</Button>
        </TextAndImageV2>
      )}
      <TextWithChecklistImg heading={isPurchase ? 'Get your Purchase-Ready Approval, lickety-split.' : 'Get your Refi-Ready Approval, lickety-split.'}>
        {isPurchase ? (
          <>
            <Paragraph>Our Purchase-Ready Approval is more reliable than a typical pre-approval — so you’ll be truly ready to purchase.</Paragraph>
            <Paragraph>What does that mean for you? Certainty and confidence — take it house shopping and make offers knowing it’s not going to disappoint later.</Paragraph>
            <Paragraph>Yours is waiting for you, rates and all.</Paragraph>
          </>
        ) : (
          <Paragraph>
            15 teeny minutes. That&apos;s all it takes to find out how much you could be saving every single month.
            <br />
            <br />
            Wait. What?
            <br />
            <br />
            Yup! Because we collect and verify your actual numbers instantly, we can give you a Refi-Ready Approval with your real rate — just like that.
            <br />
            <br />
            Heck, you can even do it from your sofa.
          </Paragraph>
        )}
      </TextWithChecklistImg>
      {!isPurchase && <FishAndText />}
      <LoanGuideConvo />
      <PlantAndCoffee heading={isPurchase ? 'Let’s set you up to win — because finding properties can be hard.' : 'Not sure if you’re ready? That’s cool.'}>
        <Paragraph light fade>
          {isPurchase ? (
            <>
              You won’t just have a Loan Guide in your corner. Our collab with Newzip gives you a search party with advisors and realtor intros get you results — and if they do you
              can get paid 0.5% of the property’s price at closing which effectively reduces your closing costs.{' '}
            </>
          ) : (
            <>
              Apply in 15 minutes to have all your questions answered and a real rate up front. If you’re into it, then go
              <br />
              to town on freeing yourself up. If you’re still not ready, just refresh your profile when you are.
            </>
          )}
        </Paragraph>
      </PlantAndCoffee>
      {isPurchase && (
        <Celebrate heading={isPurchase ? 'Let’s celebrate the excitement of buying and the thrill of moving in.' : 'Can we get a hell yeah for financial freedom?'}>
          {isPurchase && (
            <>
              <Paragraph>While we’re a lender, we know the loan itself is less important than taking you where you want to be. </Paragraph>
              <Paragraph>And where’s that? In your new home. </Paragraph>
              <Paragraph>
                Snuggling on the sofa, finding your new local coffee hangout, induing in your love of taxidermy, alphabetizing your record collection — whatever you’re into.
              </Paragraph>
            </>
          )}
          {!isPurchase && (
            <>
              <Paragraph>So, let’s get the home loan out of the way so you can plan one hell of a housewarming.</Paragraph>
              <Paragraph>While we’re a lender, we know the loan itself is less important than taking you where you want to be.</Paragraph>
              <Paragraph>And where’s that? A few years from now, poolside, eating caviar and fries without a financial worry in the world. </Paragraph>
              <Paragraph>So let’s get the loan out of the way — and take you closer to your financial happy place.</Paragraph>
            </>
          )}
        </Celebrate>
      )}
    </>
  );
});

LandingPageContent.propTypes = {
  pageType: PropTypes.string,
  isDynamicLP: PropTypes.bool,
  showDetails: PropTypes.string,
  onSearchComplete: PropTypes.func
  // ratesRef: PropTypes.object
};

LandingPageContent.defaultProps = {
  pageType: 'purchase',
  onSearchComplete: () => {},
  // ratesRef: null,
  isDynamicLP: false,
  showDetails: null
};

export default LandingPageContent;
